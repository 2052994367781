<!-- Btra Botones de Mto -->

<template>
  <div class="btramto">
    <btra
        v-if="btra_particular"
        :btra_particular="mount_btra"
        :btra_modulo="btra_modulo"
        :nopesoShow="nopesoShow"
        @onEvent="event_btramto">
    </btra>
  </div>
</template>


<script>

  import plugs from "@/common/general_plugs";
  const btra = () => plugs.groute("btra_new.vue", "comp");

  export default {
    components: { btra },
    props: {
      btra_permisos: { type: [Array, Object], required:true },
      btra_particular: { type: [Object, String], required:true },
      btra_modulo: { type: String, default: "" },
      estado: { type: [Number, String], default: "" },
      nopesoShow: { type: Number, default: 1 }
    },


    computed: {

      // devuelvo array de botones a mostrar
      mount_btra() 
      {        
        // extraigo la btra de Mto
        var bp= this.get_btraMto();

        // filtro array de botones por sus permisos 
        if (this.btra_modulo!== "m" && this.btra_modulo!== "xm") return this.filtrar_btns(bp);

        // si es una botonera de tipo mto, monto array botones según la acción recibida y filtro por sus permisos          
        return this.std_btns(bp);
      }

    },

    methods: {

      // gestor de eventos
      event_btramto(evt) 
      {
          console.log("*** event_btramto. Emit btramto: ", evt, " ***");
          this.$emit("onEvent", { accion: evt.accion });
      },

      // según btra_modulo, extraigo el array de btns de Mto de la btra particular
      get_btraMto() 
      {      
        if (!this.btra_modulo) return [];

        // copio btra particular
        //let bp = this.pass_btra_toObject();
        let bp= this.btra_particular;

        // obtengo el array de btns de Mto según el tipo de módulo recibido
        switch (this.btra_modulo) {
          case "mrow":
          case "mtop":  
          case "cab_detalle":
            if (bp.row["mto"] && bp.row["mto"] !== false) return bp.row.mto;
            return [];

          default:             
            if (bp.footer["mto"] && bp.footer["mto"] !== false) return bp.footer["mto"];
            return [];              
        }
      },

      // según la acción recibida devuelvo una botonera estandar filtrada por sus permisos
      std_btns(bp) 
      {      
          //alert(this.estado);  
          console.log('this.estado: ', this.estado, bp);  
          // monto botonera según el estado recibido
          switch (this.estado) {
            case "ver": // ver
              bp.map((elem, idx)=> this.$set(elem, 'show', /^[0234]+$/.test(idx)? 1 : 0));
              //bp.map((elem, idx)=> elem.show= /^[1236]+$/.test(idx)? 1 : 0);
              //btns = [0, 1, 1, 1, 0, 0, 1];
              break;

            case "nuevo":  // nuevo
            case "editar": // editar
              bp.map((elem, idx)=> this.$set(elem, 'show', /^[056]+$/.test(idx)? 1 : 0));
              //bp.map((elem, idx)=> elem.show= /^[456]+$/.test(idx)? 1 : 0);
              //btns = [0, 0, 0, 0, 1, 1, 1];
              break;

            default:
              bp.map((elem, idx)=> this.$set(elem, 'show', /^[0]+$/.test(idx)? 1 : 0));
              //bp.map((elem, idx)=> elem.show= /^[16]+$/.test(idx)? 1 : 0);
              //btns = [0, 1, 0, 0, 0, 0, 1];
          }

          
          // filtro por permisos particulares y de bd
          return this.filtrar_btns(bp);
      },


      // filtro botones por sus permisos
      filtrar_btns(btns) 
      {         
          btns.forEach((elem, idx) => {          
            if (idx== 0) return;
            if (elem.show && this.btra_permisos.mto[idx-1]) elem.show= Number(this.btra_permisos.mto[idx-1]);
          });

          return btns;
      },


      // convierto la btra particular recibida como String en un Objeto
      pass_btra_toObject() {        
        if (typeof this.btra_particular === "object") return this.btra_particular;        
        return this.$cfg.btra_tipo[this.btra_particular];        
      }

      
    }
  };
</script>
